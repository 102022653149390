import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Route, Redirect } from 'react-router-dom';
import loadable from 'react-loadable';
import LoadingComponent from 'components/Loading';
import axios from 'axios';
// = styles =
// 3rd
import 'styles/bootstrap/bootstrap.scss';
// custom
import 'styles/layout.scss';
import 'styles/theme.scss';
import 'styles/ui.scss';

import lightTheme from './themes/lightTheme';
import darkTheme from './themes/darkTheme';
import grayTheme from './themes/grayTheme';

import { IntlProvider } from 'react-intl';
import messages from './../translations';


import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr'; 
import '@formatjs/intl-relativetimeformat/dist/locale-data/en'; 
import IntlRelativeTimeFormat from '@formatjs/intl-relativetimeformat';
import ReactGA from 'react-ga';


// locale-data for zh
IntlRelativeTimeFormat.__addLocaleData(
  require('@formatjs/intl-relativetimeformat/dist/locale-data/fr.json'),
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en.json')
);
 
// locale-data for zh
IntlRelativeTimeFormat.__addLocaleData(
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en.json'),
  require('@formatjs/intl-relativetimeformat/dist/locale-data/fr.json')
);

let MainApp = loadable({
  loader: () => import('components/Layout/AppLayout/MainApp'),
  loading: LoadingComponent
})
let Exception = loadable({
  loader: () => import('routes/exception/'),
  loading: LoadingComponent
})
let Account = loadable({
  loader: () => import('routes/user/'),
  loading: LoadingComponent
})
let PageFullscreen = loadable({
  loader: () => import('routes/fullscreen/'),
  loading: LoadingComponent
})
let Home = loadable({
  loader: () => import('routes/homepage/'),
  loading: LoadingComponent
})


class App extends Component {
  setGA = () => {
    ReactGA.initialize('UA-216505182-1');
  };
  componentDidMount() {

    this.setGA();

    axios.interceptors.request.use(config => {
      //console.log("request sending to " + config.url);
      let header = window.localStorage.getItem("token");
      if(header && this.tokenExpired(header)){
        window.location.href = "/#/user/login";
        window.localStorage.removeItem("token")
      }
      if (!header){
        return config;
      }
      config.headers['Authorization'] = 'Bearer ' + header;
      return config;
    }, error => {
      return Promise.reject(error);  
    });
  }

  tokenExpired(token) {
    const expiry = JSON.parse(atob(token.split('.')[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }
  render() {
    const { match, location, layoutBoxed, navCollapsed, navBehind, fixedHeader, sidebarWidth, theme } = this.props;
    let materialUITheme;
    switch (theme) {
      case 'gray':
        materialUITheme = grayTheme;
        break;
      case 'dark':
        materialUITheme = darkTheme;
        break;
      default:
        materialUITheme = lightTheme;
    }

    const isRoot = location.pathname === '/' ? true : false;
    if (isRoot) {
      //console.log("is root is true not returning home page");
      return ( <Redirect to="/Index/Index" />)
    }
    //const locale = (navigator.languages && navigator.languages[0])
    //|| navigator.language
    //|| navigator.userLanguage
    //|| 'en-US';
    return (
      

        <MuiThemeProvider theme={materialUITheme}>
        <IntlProvider locale={'en'} messages={messages['en']}>
          <div id="app-inner">
            <div className="preloaderbar hide"><span className="bar" /></div>
            <div
              className={classnames('app-main h-100', {
                'fixed-header': fixedHeader,
                'nav-collapsed': navCollapsed,
                'nav-behind': navBehind,
                'layout-boxed': layoutBoxed,
                'theme-gray': theme === 'gray',
                'theme-dark': theme === 'dark',
                'sidebar-sm': sidebarWidth === 'small',
                'sidebar-lg': sidebarWidth === 'large'})
            }>
              <Route path={`${match.url}index`} component={Home} />
              <Route path={`${match.url}app`} component={MainApp} />
              <Route path={`${match.url}exception`} component={Exception} />
              <Route path={`${match.url}user`} component={Account} />
              <Route exact path="/fullscreen" component={PageFullscreen} />
            </div>
          </div>
          </IntlProvider>
        </MuiThemeProvider>
      
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  layoutBoxed: state.settings.layoutBoxed,
  navCollapsed: state.settings.navCollapsed,
  navBehind: state.settings.navBehind,
  fixedHeader: state.settings.fixedHeader,
  sidebarWidth: state.settings.sidebarWidth,
  theme: state.settings.theme,
});

export default connect(
  mapStateToProps
)(App);
